<template>
  <keep-alive>
    <v-container
      fluid
      style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
      class="d-flex flex-column"
    >
      <v-alert
        dismissible
        v-for="notification in notifications"
        :key="notification"
        style="margin-left: 100px; margin-right: 80px;"
        type="success"
      >
        {{ notification }}
      </v-alert>
      <v-alert
        dismissible
        v-for="notification in errorNotifications"
        :key="notification"
        style="margin-left: 100px; margin-right: 80px;"
        type="error"
      >
        {{ notification }}
      </v-alert>
      <div
        style="height: 100%; padding:0px; min-height: inherit; max-width: 1500px"
        class="d-flex flex-row"
      >
        <agency-left-section class="hidden-md-and-down" />
        <feed-section />
        <agency-right-section class="hidden-sm-and-down" />
      </div>
      <phone-verification-dialog
        :verifyDialog="verifyPhoneDialog"
        :onClose="onCloseVerify"
        v-if="verifyPhoneDialog"
      />
      <agency-complete-profile-dialog
        :dialog="completeProfileDialog"
        :onClose="onCloseCompleteProfileDialog"
        v-if="completeProfileDialog"
      />
      <agency-causes-dialog
        :dialog="causeDialog"
        :onClose="onCloseCausesDialog"
        v-if="causeDialog"
      />
      <agency-add-links
        :dialog="urlDialog"
        :onClose="onCloseURLDialog"
        v-if="urlDialog"
      />
      <agency-congrates-dialog
        :dialog="congratsDialog"
        :onClose="onCloseCongratsDialog"
        v-if="congratsDialog"
      />
      <v-snackbar v-model="snackbar">
        {{ errorMessage }}
      </v-snackbar>
    </v-container>
  </keep-alive>
</template>
<script>
import { mapActions, mapState } from "vuex";
import PhoneVerificationDialog from "@/components/common/PhoneVerificationDialog.vue";
import FeedSection from "../event_feeds/FeedSection.vue";
import AgencyLeftSection from "./AgencyLeftSection.vue";
import AgencyRightSection from "./AgencyRightSection.vue";
import AgencyCompleteProfileDialog from "./complete_profile_dialog/AgencyCompleteProfileDialog.vue";
import AgencyCausesDialog from "./complete_profile_dialog/AgencyCausesDialog.vue";
import AgencyAddLinks from "./complete_profile_dialog/AgencyAddLinks.vue";
import AgencyCongratesDialog from "./complete_profile_dialog/AgencyCongratesDialog.vue";
export default {
  components: {
    AgencyLeftSection,
    AgencyRightSection,
    FeedSection,
    PhoneVerificationDialog,
    AgencyCompleteProfileDialog,
    AgencyCausesDialog,
    AgencyAddLinks,
    AgencyCongratesDialog,
  },
  data() {
    return {
      loading: false,
      errorMessage: null,
      snackbar: false,
      notifications: [],
      errorNotifications: [],
      verifyPhoneDialog: false,
      completeProfileDialog: false,
      causeDialog: false,
      congratsDialog: false,
      urlDialog: false,
      passedVerification: false,
    };
  },
  methods: {
    ...mapActions("event", {
      fetchAllEvents: "fetchAllEvents",
    }),
    showErrorMessage(message) {
      this.errorMessage = message;
      this.snackbar = true;
    },
    checkPhoneVerified() {
      if (!this.profile._id || this.passedVerification) return;
      if (!this.profile.phone_verified) {
        if (!this.profile.phone_verification_code) {
          //implement send verification code
          console.log("implement send verification code");
        }
        setTimeout(() => {
          this.verifyPhoneDialog = true;
        }, 500);
        return;
      }
      this.checkCompletedProfile();
    },
    onCloseVerify() {
      this.passedVerification = true;
      this.onCloseAllDialog();
      this.checkCompletedProfile();
    },
    checkCompletedProfile() {
      if (
        this.profile &&
        this.profile._id &&
        (!this.profile.image ||
          !this.profile.who_we_are ||
          !this.profile.what_we_do)
      ) {
        setTimeout(() => {
          this.completeProfileDialog = true;
        }, 500);
        return;
      }
      this.checkCauses();
    },
    onCloseAllDialog() {
      this.verifyPhoneDialog = false;
      this.completeProfileDialog = false;
      this.causeDialog = false;
      this.urlDialog = false;
      this.congratsDialog = false;
    },
    onCloseCompleteProfileDialog() {
      this.onCloseAllDialog();
      this.checkCauses();
    },
    checkCauses() {
      if (this.profile.causes.length == 0) {
        setTimeout(() => {
          this.causeDialog = true;
        }, 500);
      } else {
        this.checkUrls();
      }
    },
    onCloseCausesDialog() {
      this.onCloseAllDialog();
      this.checkUrls();
    },
    checkUrls() {
      if (!this.profile.website) {
        setTimeout(() => {
          this.urlDialog = true;
        }, 500);
      } else {
        this.showCongratsDialog();
      }
    },
    onCloseURLDialog() {
      this.onCloseAllDialog();
      this.showCongratsDialog();
    },
    showCongratsDialog() {
      if (
        this.profile.phone_verified &&
        this.profile.image &&
        this.profile.who_we_are &&
        this.profile.what_we_do &&
        this.profile.causes.length != 0 &&
        this.profile.website
      ) {
        if (!this.profile.email_verified) {
          setTimeout(() => {
            this.congratsDialog = true;
          }, 500);
        }
      }
    },
    onCloseCongratsDialog() {
      this.onCloseAllDialog();
      this.$router.push({ name: "volunteer-profile" });
    },
  },
  computed: {
    ...mapState("auth", {
      profile: "profile",
    }),
  },
  watch: {
    profile: function(newValue) {
      if (this.$route.query.welcome && newValue) {
        this.checkPhoneVerified();
      }
    },
  },
  mounted() {
    if (this.$route.query.welcome && this.profile) {
      this.checkPhoneVerified();
    }
    this.notifications = this.$route.params.notifications;
    this.errorNotifications = this.$route.params.error;
  },
};
</script>
<style scoped></style>
